import { defineMessages } from "react-intl";

const MESSAGES = defineMessages({
  title: {
    id: "title",
    defaultMessage: "{statistic}",
  },
  hvi: {
    id: "hvi",
    defaultMessage:
      "The heat vulnerability index (HVI) combines census tract level information on race, ethnicity, age, education, employment status, poverty, housing, disability, country of origin, diabetes status, vegetation coverage, and air temperature to identify areas and populations at greatest risk from the effects of heat.",
  },

  titleWithUnit: {
    id: "titleWithUnit",
    defaultMessage: "{unit} {statistic}",
  },
  // compass data
  tree_coverage: {
    id: "tree_coverage",
    defaultMessage: "Tree Coverage",
  },
  impervious_area: {
    id: "impervious_area",
    defaultMessage: "Impervious Area",
  },
  out_of_state_ownership: {
    id: "out_of_state_ownership",
    defaultMessage: "Out of State Ownership",
  },
  cost_burdened_morgage_holders: {
    id: "cost_burdened_morgage_holders",
    defaultMessage: "Cost-Burdened Mortgage Holders",
  },
  cost_burdened_renters: {
    id: "cost_burdened_renters",
    defaultMessage: "Cost-Burdened Renters",
  },

  // demographics
  population: {
    id: "population",
    defaultMessage: "Population",
  },
  foreign_born_population: {
    id: "foreign_born_population",
    defaultMessage: "Foreign born population",
  },

  below_poverty: {
    id: "below_poverty",
    defaultMessage: "Below Poverty",
  },
  household_income_median: {
    id: "household_income_median",
    defaultMessage: "Median Household Income",
  },
  owned_one_or_more_vehicles: {
    id: "owned_one_or_more_vehicles",
    defaultMessage: "No vehicles",
  },
  unemployed: {
    id: "unemployed",
    defaultMessage: "Unemployed",
  },

  // education
  gte_bachelor_degree: {
    id: "gte_bachelor_degree",
    defaultMessage: "Bachelor Degree or Higher",
  },
  lte_high_school: {
    id: "lte_high_school",
    defaultMessage: "High School or Less",
  },

  // environment
  acetaldehyde: {
    id: "acetaldehyde",
    defaultMessage: "Acetaldehyde, ug/m^3",
  },
  gas_1_3_butadiene: {
    id: "gas_1_3_butadiene",
    defaultMessage: "1,3-Butadiene, ug/m^3",
  },
  benzene: {
    id: "benzene",
    defaultMessage: "Benzene, ug/m^3",
  },
  carbon_tetrachloride: {
    id: "carbon_tetrachloride",
    defaultMessage: "Carbon Tetrachloride, ug/m^3",
  },
  diesel_pm: {
    id: "diesel_pm",
    defaultMessage: "Diesel PM, ug/m^3",
  },
  ethylbenzene: {
    id: "ethylbenzene",
    defaultMessage: "Ethylbenzene, ug/m^3",
  },
  formaldehyde: {
    id: "formaldehyde",
    defaultMessage: "Formaldehyde, ug/m^3",
  },
  hexane: {
    id: "hexane",
    defaultMessage: "Hexane, ug/m^3",
  },
  lead_compounds: {
    id: "lead_compounds",
    defaultMessage: "Lead Compounds, ug/m^3",
  },
  manganese_compounds: {
    id: "manganese_compounds",
    defaultMessage: "Manganese Compounds, ug/m^3",
  },
  mercury_compounds: {
    id: "mercury_compounds",
    defaultMessage: "Mercury Compounds, ug/m^3",
  },
  methanol: {
    id: "methanol",
    defaultMessage: "Methanol, ug/m^3",
  },
  methyl_chloride: {
    id: "methyl_chloride",
    defaultMessage: "Methyl Chloride, ug/m^3",
  },
  nickel_compounds: {
    id: "nickel_compounds",
    defaultMessage: "Nickel Compounds, ug/m^3",
  },
  pm25: {
    id: "pm25",
    defaultMessage: "PM 2.5 (Fine Particulate Matter, ug/m^3)",
  },
  toluene: {
    id: "toluene",
    defaultMessage: "Toluene, ug/m^3",
  },
  xylenes: {
    id: "xylenes",
    defaultMessage: "Xylenes, ug/m^3",
  },

  //food access

  lilatracts_1and10: {
    id: "lilatracts_1and10",
    defaultMessage:
      "Low income and low access tract measured at 1 mile for urban areas and 10 miles for rural areas",
  },
  lilatracts_halfand10: {
    id: "lilatracts_halfand10",
    defaultMessage:
      "Low income and low access tract measured at 1/2 mile for urban areas and 10 miles for rural areas",
  },
  lilatracts_1and20: {
    id: "lilatracts_1and20",
    defaultMessage:
      "Low income and low access tract measured at 1 mile for urban areas and 20 miles for rural areas",
  },
  lilatracts_vehicle: {
    id: "lilatracts_vehicle",
    defaultMessage:
      "Low income and low access tract using vehicle access or low income and low access tract measured at 20 miles",
  },
  la1and10: {
    id: "la1and10",
    defaultMessage:
      "Low access tract at 1 mile for urban areas and 10 miles for rural areas",
  },
  lahalfand10: {
    id: "lahalfand10",
    defaultMessage:
      "Low access tract at 1/2 mile for urban areas and 10 miles for rural areas",
  },
  la1and20: {
    id: "la1and20",
    defaultMessage:
      "Low access tract at 1 mile for urban areas and 20 miles for rural areas",
  },
  latractsvehicle_20: {
    id: "latractsvehicle_20",
    defaultMessage:
      "Low access tract using vehicle access and at 20 miles in rural areas",
  },

  // health
  ckd: {
    id: "ckd",
    defaultMessage: "Chronic Kidney Disease",
  },
  ckd_cdc_less_30: {
    id: "ckd_cdc_less_30",
    defaultMessage: "CDC < 30",
  },
  ckd_cdc_less_60: {
    id: "ckd_cdc_less_60",
    defaultMessage: "CDC < 60",
  },
  ckd_kdigo_less_20: {
    id: "ckd_kdigo_less_20",
    defaultMessage: "KDIGO < 20",
  },
  ckd_kdigo_less_30: {
    id: "ckd_kdigo_less_30",
    defaultMessage: "KDIGO < 30",
  },
  ckd_kdigo_less_60: {
    id: "ckd_kdigo_less_60",
    defaultMessage: "KDIGO < 60",
  },
  diabetes_total_diabetes: {
    id: "diabetes_total_diabetes",
    defaultMessage: "Total Diabetes",
  },
  htn: {
    id: "htn",
    defaultMessage: "Hypertension",
  },
  htn_bp: {
    id: "htn_bp_htn",
    defaultMessage: "High Blood Pressure",
  },
  htn_diag: {
    id: "htn_diag_htn",
    defaultMessage: "Diagnosed Hypertension",
  },
  obesity: {
    id: "obesity",
    defaultMessage: "Overweight/Obesity",
  },
  obesity_class_1: {
    id: "obesity_class_1",
    defaultMessage: "BMI 30.0 to 34.9",
  },
  obesity_class_2: {
    id: "obesity_class_2",
    defaultMessage: "BMI 35.0 to 39.9",
  },
  obesity_class_3: {
    id: "obesity_class_3",
    defaultMessage: "BMI ≥ 40",
  },
  obesity_ow: {
    id: "obesity_ow",
    defaultMessage: "Overweight",
  },
  obesity_total: {
    id: "obesity_total",
    defaultMessage: "Total",
  },
  lbw: {
    id: "lbw",
    defaultMessage: "Low Birth Weight",
  },
  lbw_preterm: {
    id: "lbw_preterm",
    defaultMessage: "Pre-term Birth",
  },
  lbw_late_preterm: {
    id: "lbw_late_preterm",
    defaultMessage: "Late Pre-term Birth",
  },
  lbw_very_preterm: {
    id: "lbw_very_preterm",
    defaultMessage: "Very Pre-term Birth",
  },
  lbw_extrem_preterm: {
    id: "lbw_extrem_preterm",
    defaultMessage: "Extremely Pre-term Birth",
  },
  lbw_very_low: {
    id: "lbw_very_low",
    defaultMessage: "Very Low Birth Weight",
  },
  lbw_low: {
    id: "lbw_low",
    defaultMessage: "Low Birth Weight",
  },
  mi: {
    id: "mi",
    defaultMessage: "Myocardial Infarction",
  },
  mi_diag: {
    id: "mi_diag",
    defaultMessage: "Diagnosed Myocardial Infarction",
  },
  stroke: {
    id: "stroke",
    defaultMessage: "Stroke",
  },
  stroke_diag: {
    id: "stroke_diag",
    defaultMessage: "Diagnosed Stroke",
  },
  diabetes: {
    id: "diabetes",
    defaultMessage: "Diabetes",
  },
  diabetes_diag: {
    id: "diabetes_diag",
    defaultMessage: "Diagnosed Diabetes",
  },

  // housing
  gross_rent_median: {
    id: "gross_rent_median",
    defaultMessage: "Median Gross Rent",
  },
  home_value_median: {
    id: "home_value_median",
    defaultMessage: "Median Home Value",
  },
  person_one_more_room: {
    id: "person_one_more_room",
    defaultMessage: "More than One Person Per Room",
  },
  eviction_count: {
    id: "eviction_count",
    defaultMessage: "Evictions",
  },
  eviction_rate: {
    id: "eviction_rate",
    defaultMessage: "Eviction Rate",
  },
  eviction_filings: {
    id: "eviction_filings",
    defaultMessage: "Eviction Filing",
  },
  eviction_filing_rate: {
    id: "eviction_filing_rate",
    defaultMessage: "Eviction Filing Rate",
  },

  // indices
  adi: {
    id: "adi",
    defaultMessage: "Area Deprivation Index",
  },
  ahrq_ses: {
    id: "ahrq_ses",
    defaultMessage: "Socioeconomic Status (AHRQ)",
  },
  coi: {
    id: "coi",
    defaultMessage: "Child Opportunity Index",
  },
  svi: {
    id: "svi",
    defaultMessage: "Social Vulnerability Index",
  },

  // infrastructure
  parks: {
    id: "parks",
    defaultMessage: "Parks",
  },
  tobacco_retail_outlets: {
    id: "tobacco_retail_outlets",
    defaultMessage: "Tobacco Retail Outlets",
  },
  religious_locations: {
    id: "religious_locations",
    defaultMessage: "Religious Locations",
  },
  bar_nightclub: {
    id: "bar_nightclub",
    defaultMessage: "Bar or Nightclub",
  },
  liquor_stores: {
    id: "liquor_stores",
    defaultMessage: "Liquor Stores",
  },
  walkability: {
    id: "walkability",
    defaultMessage: "Walkability",
  },
  bb: {
    id: "bb",
    defaultMessage: "Broadband",
  },
  bb_any_connection: {
    id: "bb_any_connection",
    defaultMessage: "Any Connection",
  },
  bb_high_speed: {
    id: "bb_high_speed",
    defaultMessage: "High Speed Connection",
  },

  public: {
    id: "public",
    defaultMessage: "Public",
  },
  total_population: {
    id: "total_population",
    defaultMessage: "Total Population",
  },
  ethnicity_hispanic: {
    id: "ethnicity_hispanic",
    defaultMessage: "Hispanic",
  },
  ethnicity_non_hispanic: {
    id: "ethnicity_non_hispanic",
    defaultMessage: "Non-Hispanic",
  },
  population_density: {
    id: "population_density",
    defaultMessage: "Population Density",
  },
  age_10_to_14: {
    id: "age_10_to_14",
    defaultMessage: "Age 10 to 14",
  },
  age_15_to_17: {
    id: "age_15_to_17",
    defaultMessage: "Age 15 to 17",
  },
  age_18_to_29: {
    id: "age_18_to_29",
    defaultMessage: "Age 18 to 29",
  },
  age_30_to_44: {
    id: "age_30_to_44",
    defaultMessage: "Age 30 to 44",
  },
  age_45_to_64: {
    id: "age_45_to_64",
    defaultMessage: "Age 45 to 64",
  },
  age_50_to_64: {
    id: "age_50_to_64",
    defaultMessage: "Age 50 to 64",
  },
  age_5_to_9: {
    id: "age_5_to_9",
    defaultMessage: "Age 5 to 9",
  },
  age_gt_1: {
    id: "age_gt_1",
    defaultMessage: "Age greater than 1",
  },
  age_gte_65: {
    id: "age_gte_65",
    defaultMessage: "Age 65 and older",
  },
  age_gte_80: {
    id: "age_gte_80",
    defaultMessage: "Age 80 and older",
  },
  age_lt_5: {
    id: "age_lt_5",
    defaultMessage: "Age less than 5",
  },
  age_lt_18: {
    id: "age_lt_18",
    defaultMessage: "Age less than 17",
  },
  age_median: {
    id: "age_median",
    defaultMessage: "Median Age",
  },
  bachelor_degree_or_higher: {
    id: "bachelor_degree_or_higher",
    defaultMessage: "Bachelor Degree or Higher",
  },
  hs_diploma_or_less: {
    id: "hs_diploma_or_less",
    defaultMessage: "High School Diploma or Less",
  },
  race_more_than_one: {
    id: "race_more_than_one",
    defaultMessage: "More than One",
  },
  race_native_pacific_islander: {
    id: "race_native_pacific_islander",
    defaultMessage: "Native Hawaiian and Other Pacific Islander",
  },
  race_american_indian_alaska_native: {
    id: "race_american_indian_alaska_native",
    defaultMessage: "American Indian and Alaska Native",
  },
  race_asian: {
    id: "race_asian",
    defaultMessage: "Asian",
  },
  race_black: {
    id: "race_black",
    defaultMessage: "Black or African American",
  },
  race_white: {
    id: "race_white",
    defaultMessage: "White",
  },
  sex_female: {
    id: "sex_female",
    defaultMessage: "Female",
  },
  sex_male: {
    id: "sex_male",
    defaultMessage: "Male",
  },
  female_population: {
    id: "female_population",
    defaultMessage: "Female Population",
  },
  male_population: {
    id: "male_population",
    defaultMessage: "Male Population",
  },
  population_above_1: {
    id: "population_above_1",
    defaultMessage: "Population Above 1",
  },
  population_0_4: {
    id: "population_0_4",
    defaultMessage: "Population 0 to 4",
  },
  population_5_9: {
    id: "population_5_9",
    defaultMessage: "Population 5 to 9",
  },
  population_10_14: {
    id: "population_10_14",
    defaultMessage: "Population 10 to 14",
  },
  population_15_17: {
    id: "population_15_17",
    defaultMessage: "Population 15 to 17",
  },
  population_18_29: {
    id: "population_18_29",
    defaultMessage: "Population 18 to 29",
  },
  population_30_44: {
    id: "population_30_44",
    defaultMessage: "Population 30 to 44",
  },
  population_45_64: {
    id: "population_45_64",
    defaultMessage: "Population 45 to 64",
  },
  population_50_64: {
    id: "population_50_64",
    defaultMessage: "Population 50 to 64",
  },
  population_above_65: {
    id: "population_above_65",
    defaultMessage: "Population Above 65",
  },
  population_above_80: {
    id: "population_above_80",
    defaultMessage: "Population Above 80",
  },
  median_age: {
    id: "median_age",
    defaultMessage: "Median Age",
  },
  population_foreign_born: {
    id: "population_foreign_born",
    defaultMessage: "Population Foreign Born",
  },
  population_asian: {
    id: "population_asian",
    defaultMessage: "Population Asian",
  },
  population_black: {
    id: "population_black",
    defaultMessage: "Population Black",
  },
  population_white: {
    id: "population_white",
    defaultMessage: "Population White",
  },
  non_institutionalized_population: {
    id: "non_institutionalized_population",
    defaultMessage: "Total Non-Institutionalized Population",
  },

  gini_index: {
    id: "gini_index",
    defaultMessage: "Gini Index",
  },
  per_capita_income: {
    id: "per_capita_income",
    defaultMessage: "Total Per Capita Income",
  },
  household_food_stamps: {
    id: "household_food_stamps",
    defaultMessage: "Household Food Stamps",
  },
  household_public_assistance: {
    id: "household_public_assistance",
    defaultMessage: "Household Public Assistance",
  },
  et: {
    id: "et",
    defaultMessage: "Economy Transportation",
  },
  et_commute_time_15min: {
    id: "et_commute_time_15min",
    defaultMessage: "Commute Time 15 Minutes",
  },
  et_commute_time_29min: {
    id: "et_commute_time_29min",
    defaultMessage: "Commute Time 29 Minutes",
  },
  et_commute_time_59min: {
    id: "et_commute_time_59min",
    defaultMessage: "Commute Time 59 Minutes",
  },
  et_commute_time_60min_up: {
    id: "et_commute_time_60min_up",
    defaultMessage: "Commute Time 60 Minutes and Up",
  },
  et_drive_to_work: {
    id: "et_drive_to_work",
    defaultMessage: "Drive to Work",
  },
  et_household_no_vehicle: {
    id: "et_household_no_vehicle",
    defaultMessage: "Household No Vehicle",
  },
  et_public_commute_15min: {
    id: "et_public_commute_15min",
    defaultMessage: "Public Commute 15 Minutes",
  },
  et_public_commute_29min: {
    id: "et_public_commute_29min",
    defaultMessage: "Public Commute 29 Minutes",
  },
  et_public_commute_59min: {
    id: "et_public_commute_59min",
    defaultMessage: "Public Commute 59 Minutes",
  },
  et_public_commute_60min_up: {
    id: "et_public_commute_60min_up",
    defaultMessage: "Public Commute 60 Minutes and Up",
  },
  et_public_transit: {
    id: "et_public_transit",
    defaultMessage: "Transit",
  },
  et_walk_to_work: {
    id: "et_walk_to_work",
    defaultMessage: "Walk to Work",
  },
  et_work_no_car: {
    id: "et_work_no_car",
    defaultMessage: "Work No Car",
  },
  graduate_degree: {
    id: "graduate_degree",
    defaultMessage: "Graduate Degree",
  },
  hi: {
    id: "hi",
    defaultMessage: "Health Insurance",
  },
  hi_medicaid_any: {
    id: "hi_medicaid_any",
    defaultMessage: "Medicaid Any",
  },
  hi_medicaid_any_below_64: {
    id: "hi_medicaid_any_below_64",
    defaultMessage: "Medicaid Any Below 64",
  },
  hi_medicare_only: {
    id: "hi_medicare_only",
    defaultMessage: "Medicare Only",
  },
  hi_private_employer_direct: {
    id: "hi_private_employer_direct",
    defaultMessage: "Private Employer Direct",
  },
  hi_private_any: {
    id: "hi_private_any",
    defaultMessage: "Private Any",
  },
  hi_private_employer: {
    id: "hi_private_employer",
    defaultMessage: "Private Employer",
  },
  hi_private_medicare: {
    id: "hi_private_medicare",
    defaultMessage: "Private Medicare",
  },
  hi_private_self_employed: {
    id: "hi_private_self_employed",
    defaultMessage: "Private Self Employed",
  },
  hi_medicare_public_only: {
    id: "hi_medicare_public_only",
    defaultMessage: "Medicare Public Only",
  },
  hi_public_other: {
    id: "hi_public_other",
    defaultMessage: "Other",
  },
  hi_tricare_va: {
    id: "hi_tricare_va",
    defaultMessage: "Tricare VA",
  },
  hi_tricare: {
    id: "hi_tricare_va",
    defaultMessage: "Tricare VA",
  },
  hi_uninsured: {
    id: "hi_uninsured",
    defaultMessage: "Uninsured",
  },
  hi_uninsure: {
    id: "hi_uninsure",
    defaultMessage: "Uninsured",
  },
  diabetes_good_control: {
    id: "diabetes_good_control",
    defaultMessage: "test",
  },
  diabetes_poor_control: {
    id: "diabetes_poor_control",
    defaultMessage: "test",
  },
  diabetes_prediabetes_only: {
    id: "diabetes_prediabetes_only",
    defaultMessage: "test",
  },
  diabetes_prediabetes_registry: {
    id: "diabetes_prediabetes_registry",
    defaultMessage: "test",
  },
  htn_hypertension_blood_pressure: {
    id: "htn_hypertension_blood_pressure",
    defaultMessage: "test",
  },
  htn_hypertension_diagnosed: {
    id: "htn_hypertension_diagnosed",
    defaultMessage: "test",
  },
  mi_myocardial_infarction_total: {
    id: "mi_myocardial_infarction_total",
    defaultMessage: "test",
  },
  obesity_class_1_obesity: {
    id: "obesity_class_1_obesity",
    defaultMessage: "test",
  },
  obesity_class_2_obesity: {
    id: "obesity_class_2_obesity",
    defaultMessage: "test",
  },
  obesity_class_3_obesity: {
    id: "obesity_class_3_obesity",
    defaultMessage: "test",
  },
  obesity_overweight: {
    id: "obesity_overweight",
    defaultMessage: "test",
  },
  stroke_total: {
    id: "stroke_total",
    defaultMessage: "test",
  },

  civilian_labor_force: {
    id: "civilian_labor_force",
    defaultMessage: "Civilian Labor Force",
  },
  civilian_employed_population: {
    id: "civilian_employed_population",
    defaultMessage: "Civilian Employed Population",
  },
  housing_units: {
    id: "housing_units",
    defaultMessage: "Housing Units",
  },
  households: {
    id: "households",
    defaultMessage: "Households",
  },
  household_size_average: {
    id: "household_size_average",
    defaultMessage: "Household Size Average",
  },
  rent_median: {
    id: "rent_median",
    defaultMessage: "Rent Median",
  },
  year_built_median: {
    id: "year_built_median",
    defaultMessage: "Year Built Median",
  },
  units_10_or_more: {
    id: "units_10_or_more",
    defaultMessage: "Units 10 or More",
  },
  units_built_1979: {
    id: "units_built_1979",
    defaultMessage: "Units Built 1979",
  },
  different_state: {
    id: "different_state",
    defaultMessage: "Moved-Different State",
  },
  occupied_house_no_fuel: {
    id: "occupied_house_no_fuel",
    defaultMessage: "House No Fuel",
  },
  occupied_house_utility_gas: {
    id: "occupied_house_utility_gas",
    defaultMessage: "House Utility Gas",
  },
  occupied_house_bottled_tank_lp_gas: {
    id: "occupied_house_bottled_tank_lp_gas",
    defaultMessage: "House Bottled Tank LP Gas",
  },
  occupied_house_oil: {
    id: "occupied_house_oil",
    defaultMessage: "House Oil",
  },
  occupied_house_wood: {
    id: "occupied_house_wood",
    defaultMessage: "House Wood",
  },
  occupied_house_coal: {
    id: "occupied_house_coal",
    defaultMessage: "House Coal",
  },
  occupied_house_other: {
    id: "occupied_house_other",
    defaultMessage: "House Other",
  },
  occupied_house_electricity: {
    id: "occupied_house_electricity",
    defaultMessage: "House Electricity",
  },
  occupied_house_solar_energy: {
    id: "occupied_house_solar_energy",
    defaultMessage: "House Solar Energy",
  },
  in_county_move_1_year_ago: {
    id: "in_county_move_1_year_ago",
    defaultMessage: "In County Move 1 Year Ago",
  },
  in_state_move_1_year_ago: {
    id: "in_state_move_1_year_ago",
    defaultMessage: "In State Move 1 Year Ago",
  },
  more_than_one_person_per_room: {
    id: "more_than_one_person_per_room",
    defaultMessage: "More Than One Person Per Room",
  },
  lacking_kitchen_facilities: {
    id: "lacking_kitchen_facilities",
    defaultMessage: "Lacking Kitchen Facilities",
  },
  owner_occupied_housing_units: {
    id: "owner_occupied_housing_units",
    defaultMessage: "Owner Occupied Housing Units",
  },
  owner_occupied_housing_units_with_children: {
    id: "owner_occupied_housing_units_with_children",
    defaultMessage: "Owner Occupied Housing Units With Children",
  },
  lacking_plumbing_facilities: {
    id: "lacking_plumbing_facilities",
    defaultMessage: "Lacking Plumbing Facilities",
  },
  renter_occupied_housing_units: {
    id: "renter_occupied_housing_units",
    defaultMessage: "Renter Occupied Housing Units",
  },
  vacant_housing_units: {
    id: "vacant_housing_units",
    defaultMessage: "Vacant Housing Units",
  },
  bb_no_computer_device: {
    id: "no_computer_device",
    defaultMessage: "No Computer Device",
  },
  bb_with_smartphone: {
    id: "with_smartphone",
    defaultMessage: "With Smartphone",
  },
  bb_with_tablet_only: {
    id: "with_tablet_only",
    defaultMessage: "With Tablet Only",
  },
  bb_with_pc_only: {
    id: "with_pc_only",
    defaultMessage: "With PC Only",
  },
  bb_with_internet_subscription: {
    id: "with_internet_subscription",
    defaultMessage: "With Internet Subscription",
  },
  bb_with_internet_no_subscription: {
    id: "with_internet_no_subscription",
    defaultMessage: "With Internet No Subscription",
  },
  bb_with_broadband: {
    id: "with_broadband",
    defaultMessage: "With Broadband",
  },
  bb_with_broadband_no_internet_subscription: {
    id: "with_broadband_no_internet_subscription",
    defaultMessage: "With Broadband No Internet Subscription",
  },
  bb_with_broadband_any: {
    id: "with_broadband_any",
    defaultMessage: "With Broadband Any",
  },
  bb_no_internet_access: {
    id: "no_internet_access",
    defaultMessage: "No Internet Access",
  },
  cdc: {
    id: "cdc",
    defaultMessage: "CDC Places",
  },
  cdc_teeth_lost: {
    id: "cdc_teeth_lost",
    defaultMessage: "Teeth Lost",
  },
  cdc_arthritis: {
    id: "cdc_arthritis",
    defaultMessage: "Arthritis",
  },
  cdc_bingedrink: {
    id: "cdc_bingedrink",
    defaultMessage: "Binge Drinking",
  },
  cdc_cancer: {
    id: "cdc_cancer",
    defaultMessage: "Cancer",
  },
  cdc_cervical: {
    id: "cdc_cervical",
    defaultMessage: "Cervical Cancer",
  },
  cdc_cholesterol: {
    id: "cdc_cholesterol",
    defaultMessage: "Cholesterol Screening",
  },
  cdc_kidney: {
    id: "cdc_kidney",
    defaultMessage: "Kidney Disease",
  },
  cdc_pulmonary: {
    id: "cdc_pulmonary",
    defaultMessage: "Pulmonary Disease",
  },
  cdc_heart: {
    id: "cdc_heart",
    defaultMessage: "Heart Disease",
  },
  cdc_asthma: {
    id: "cdc_asthma",
    defaultMessage: "Asthma",
  },
  cdc_insurance: {
    id: "cdc_insurance",
    defaultMessage: "Insurance",
  },
  cdc_smoking: {
    id: "cdc_smoking",
    defaultMessage: "Smoking",
  },
  cdc_depression: {
    id: "cdc_depression",
    defaultMessage: "Depression",
  },
  cdc_diabetes: {
    id: "cdc_diabetes",
    defaultMessage: "Diabetes",
  },
  cdc_health: {
    id: "cdc_health",
    defaultMessage: "Health",
  },
  cdc_fecal: {
    id: "cdc_fecal",
    defaultMessage: "Fecal Occult Blood Test",
  },
  cdc_bphigh: {
    id: "cdc_bphigh",
    defaultMessage: "High Blood Pressure",
  },
  cdc_cholesterol_high: {
    id: "cdc_cholesterol_high",
    defaultMessage: "High Cholesterol",
  },
  cdc_mammography: {
    id: "cdc_mammography",
    defaultMessage: "Mammography",
  },
  cdc_mental: {
    id: "cdc_mental",
    defaultMessage: "Mental Health",
  },
  cdc_leisure: {
    id: "cdc_leisure",
    defaultMessage: "Leisure",
  },
  cdc_obesity: {
    id: "cdc_obesity",
    defaultMessage: "Obesity",
  },
  cdc_preventive_m: {
    id: "cdc_preventive_m",
    defaultMessage: "Male Preventive Care",
  },
  cdc_preventive_f: {
    id: "cdc_preventive_f",
    defaultMessage: "Female Preventive Care",
  },
  cdc_physical: {
    id: "cdc_physical",
    defaultMessage: "Physical Activity",
  },
  cdc_sleep: {
    id: "cdc_sleep",
    defaultMessage: "Sleep",
  },
  cdc_strokes: {
    id: "cdc_strokes",
    defaultMessage: "Strokes",
  },
  cdc_medicine_bp: {
    id: "cdc_medicine_bp",
    defaultMessage: "Taking Medicine for High Blood Pressure",
  },
  cdc_dentist: {
    id: "cdc_dentist",
    defaultMessage: "Visit Dentist",
  },
  cdc_doctor: {
    id: "cdc_doctor",
    defaultMessage: "Visit Doctor",
  },
});

export default MESSAGES;
